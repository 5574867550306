const phoneTemplate = {
    telephoneMask: true,
    patterns: ['(99) 9999-9999',
    '(99) 99999-9999',
    '+9 (99) 9999-9999',
    '+99 (99) 9999-9999',
    '+99 (99) 99999-9999'],
    inputAttributes: { type: 'tel', autofill: 'tel' },
    validator: (value) => {
        const phoneRegex = /[^\.\+\(\)\-\s\d]/;
        const phoneNumber = value.replace(/\D/g, "");
        
        if (phoneRegex.test(value)){
            return `'${value[value.search(phoneRegex)]}' é um caractere inválido para telefone.`
        }
        else if (phoneNumber.length < 10) {
            return `Seu telefone deve conter ao menos 10 caracteres.`
        }
        else if (phoneNumber.length > 15) {
            return `O telefone deve conter no máximo 15 caracteres.`
        }
        return true;
    }
}

export default phoneTemplate;